<template>
  <TransitionRoot as="template" :show="mobileMenuOpen">
    <Dialog as="section" static class="z-50 fixed inset-0 overflow-hidden" @close="toggleMobileMenu" :open="mobileMenuOpen">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-y-0 left-0 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="-translate-x-full">
            <div class="relative w-screen max-w-md">
              <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 pt-4 pr-2 flex sm:pr-4">
                  <button class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="toggleMobileMenu">
                    <span class="sr-only">Close panel</span>
                    <XIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="h-full flex flex-col py-6 bg-skin-section shadow-xl overflow-y-auto">
                <div class="px-4 sm:px-6">
                  <DialogTitle class="text-lg font-medium text-gray-900 sr-only">
                    Navigation Menu
                  </DialogTitle>
                </div>
                <div class="mt-6 relative flex-1 px-4 sm:px-6">
                  <side-nav-bar/>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import {XIcon} from '@heroicons/vue/outline'
import {mapActions, mapState} from "vuex";
import SideNavBar from "@/components/SideNavBar";

export default {
  name: 'side-nav-bar-mobile',
  computed: {
    ...mapState(["mobileMenuOpen"])
  },
  methods: {
    ...mapActions(['toggleMobileMenu']),
  },
  components: {
    SideNavBar,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
}
</script>

<style scoped>

</style>