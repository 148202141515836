import ApiService from "@/services/api.service";

export const afcRequest = {
    namespaced: true,
    state() {
        return {
            details: {},
            errors: undefined
        }
    },
    actions: {
        // loadData( {commit}, sendDataObj) {
        //     //console.log('inputData = ' + sendDataObj.inputData + ', email = ' + sendDataObj.email)
        //     return ApiService.postJson(`/afc/${sendDataObj.email}/get-or-save`, sendDataObj.inputData)
        //         .then(response => {
        //             //console.log(response.data)
        //             commit('saveData', response.data)
        //         })
        //         .catch(error => Promise.reject(error))
        // },
        sendAndLoadData ( {commit}, sendDataObj) {
            //console.log('inputData = ' + sendDataObj.inputData + ', email = ' + sendDataObj.email)
            return ApiService.postJson(`/afc/${sendDataObj.email}/get-or-save`, sendDataObj.inputData)
                .then(response => {
                    //console.log(response.data)
                    commit('saveData', response.data)
                })
                .catch(error => Promise.reject(error))
        },
        // getOrderInfo( {commit}, afcOrigOrderNo) {
        //     return ApiService.post(`/afc/${afcOrigOrderNo}/order-info`)
        //         .then(response => {
        //             commit('saveData', response.data)
        //         })
        //         .catch(error => Promise.reject(error))
        // }
    },
    mutations: {
        saveData(state, response) {
            if (response.errors === null) {
                state.details = JSON.parse(response.afcInfo)
                state.errors = undefined
            } else {
                state.errors = JSON.parse(response.errors)
            }
        }
    }
}