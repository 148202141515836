<template>
  <div class="bg-gradient-to-t from-skin-accent1-50 sm:-mx-4 min-h-full relative overflow-hidden">
    <div
        class="animate-blob mix-blend-normal opacity-30 filter blur-xl absolute top-0 -left-4 w-384 h-384 bg-skin-accent1 rounded-full"></div>
    <div
        class="animate-blob animation-delay-2000 mix-blend-normal opacity-30 filter blur-xl absolute top-0 -right-4 w-384 h-384 bg-skin-accent2 rounded-full"></div>
    <div
        class="animate-blob animation-delay-4000 mix-blend-normal opacity-30 filter blur-xl absolute -bottom-8 left-28 w-384 h-384 bg-skin-accent3 rounded-full"></div>
    <svg class="absolute inset-0 z-0" viewBox="0 0 54 64" version="1.1" xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
      <defs></defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
        <path class="beat-loader" d="M0.5,38.5 L16,38.5 L19,25.5 L24.5,57.5 L31.5,7.5 L37.5,46.5 L43,38.5 L53.5,38.5"
              id="Path-2" stroke-width="2" sketch:type="MSShapeGroup"></path>
      </g>
    </svg>
    <div class="flex items-center flex-col z-10">
      <div class="flex flex-col px-8 w-full max-w-max xl:px-48 py-24">
        <h1 class="z-10 text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl text-skin-heading">
          <span class="block">Convenient, powerful</span>
          {{ ' ' }}
          <span
              class="block bg-clip-text text-transparent bg-gradient-to-r from-skin-accent1 via-skin-accent2 to-skin-accent3">Momentum Intranet</span>
        </h1>
        <router-link
            v-if="isAuthenticated"
            :to="{name: 'Dashboard'}"
            class="z-10 text-center my-20 px-6 py-8 bg-gradient-to-r from-skin-accent1 to-skin-accent3 text-skin-inverted rounded-xl font-medium text-2xl shadow-lg hover:bg-gradient-to-l transition duration-500">
          Go To Dashboard
        </router-link>
        <button
            v-else
            @click="signIn"
            class="z-10 text-center my-20 px-6 py-8 bg-gradient-to-r from-skin-accent1 to-skin-accent3 text-skin-inverted rounded-xl font-medium text-2xl shadow-lg hover:bg-gradient-to-l transition duration-500 "
        >
          Sign In
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import showError from "@/services/error.service";

export default {
  name: 'Home',
  computed: {
    ...mapState('auth', ["isAuthenticated"])
  },
  methods: {
    signIn() {
      this.$store.dispatch('auth/signIn')
          .then(() => {
            this.$store.dispatch('auth/getUserData')
                .then(() => {
                  this.$store.dispatch('auth/getMenu')
                      .then(() => {
                        this.$router.push({name: 'Dashboard'})
                      })
                      .catch(error => {
                        this.$notify({
                          group: 'important',
                          title: "Error Getting Menu",
                          type: 'error',
                          text: showError(error)
                        }, 10000)
                        this.$router.push({name: 'Home'})
                      })
                })
                .catch(error => {
                  this.$notify({
                    group: 'important',
                    title: "Error Getting User Data",
                    type: 'error',
                    text: showError(error)
                  }, 10000)
                  this.$router.push({name: "ServiceUnavailable"})
                })
          })
          .catch(error => {
            this.$notify({
              group: 'important',
              title: "Error Signing In",
              type: 'error',
              text: showError(error)
            }, 10000)
            this.$router.push({name: 'Home'})
          })
    }
  }
}
</script>

<style>

.beat-loader {
  stroke: rgba(var(--color-accent1), .2);
  stroke-dasharray: 175;
  stroke-dashoffset: 350;
  -webkit-animation: draw 5.4s linear;
  -moz-animation: draw 5.4s linear;
  animation: draw 5.4s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@-moz-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>