<template>
  <div class="z-50 flex max-w-sm w-full mx-auto bg-skin-card shadow-md rounded-lg overflow-hidden mt-4">
    <div class="flex justify-center items-center w-12" :class="accentBackground">
      <component :is="icon" class="h-6 w-6 fill-current text-skin-inverted"></component>
    </div>
    <div class="-mx-3 py-2 px-4 flex-1 relative">
      <div class="mx-3">
        <span class="font-semibold" :class="accentText">
          <slot name="title" />
        </span>
        <p class="text-sm">
          <slot />
        </p>
      </div>
      <div class="absolute top-0 right-0 px-4 py-3 flex z-50 pointer-events-auto">
        <button @click="$emit('closeNotification')">
          <XIcon class="h-4 w-4 fill-current text-skin-heading"></XIcon>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import {CheckIcon, ExclamationCircleIcon, InformationCircleIcon, XIcon} from '@heroicons/vue/solid'

export default {
  name: 'app-notification',
  emits: ['closeNotification'],
  props: {
    notification: {}
  },
  components: {
    CheckIcon,
    InformationCircleIcon,
    ExclamationCircleIcon,
    XIcon
  },
  data() {
    return {
      accentTexts: {
        info: 'text-blue-500',
        success: 'text-green-500',
        error: 'text-red-500'
      },
      accentBackgrounds: {
        info: 'bg-blue-500',
        success: 'bg-green-500',
        error: 'bg-red-500'
      },
      icons: {
        info: 'InformationCircleIcon',
        success: 'CheckIcon',
        error: 'ExclamationCircleIcon'
      }
    }
  },
  computed: {
    type() {
      if (this.notification.type && Object.prototype.hasOwnProperty.call(this.icons, this.notification.type)) {
        return this.notification.type
      }
      return 'info'
    },
    accentBackground() {
      return this.accentBackgrounds[this.type]
    },
    accentText() {
      return this.accentTexts[this.type]
    },
    icon() {
      return this.icons[this.type]
    }
  }
}
</script>