<template>
  <div>
    <ul class="pr-4">
      <li v-for="(menuItem, m) in menu" :key="m">
        <button v-if="isModule(menuItem)" @click="handleClick(menuItem)"
                :class="{'text-skin-accent1': isActiveRoute(menuItem)}"
                class="flex items-center group rounded-md w-full px-2 py-2 text-skin-heading focus:outline-none focus:ring-1 focus:ring-skin-accent1 hover:text-skin-accent1">
          <!--          <component :is="menuItem.icon" class="w-8 h-8"></component>-->
          <g v-html="getIcon(menuItem)"></g>
          <span class="ml-4 text-xl">{{ menuItem.displayName }}</span>
        </button>
        <Disclosure v-else v-slot="{ open }">
          <DisclosureButton class="flex justify-between items-center w-full px-2 py-2 text-left text-skin-heading">
            <div class="flex items-center">
              <g v-html="getIcon(menuItem)"></g>
              <span class="ml-4 text-xl">{{ menuItem.groupName }}</span>
            </div>
            <ChevronUpIcon
                :class="open ? '' : 'transform rotate-180'"
                class="w-5 h-5 text-skin-base"
            />
          </DisclosureButton>
          <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-out"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
          >
            <DisclosurePanel class="pl-4 pt-4 pb-2">
              <ul>
                <li v-for="(subItem, s) in menuItem.children" :key="s">
                  <button
                      v-if="isModule(subItem)"
                      @click="handleClick(subItem)"
                      :class="{'text-skin-accent1': isActiveRoute(subItem)}"
                      class=" text-left text-skin-heading rounded-md w-full px-2 py-2 focus:outline-none focus:ring-1 focus:ring-skin-accent1 hover:text-skin-accent1"
                  >
                    <span>{{ subItem.displayName }}</span>
                  </button>
                  <Disclosure v-else v-slot="{ open }">
                    <DisclosureButton class="flex justify-between items-center w-full px-2 py-2 text-left text-skin-heading">
                      <div class="flex items-center">
                        <span class="ml-1 text-xl">{{ subItem.groupName }}</span>
                      </div>
                      <ChevronUpIcon
                          :class="open ? '' : 'transform rotate-180'"
                          class="w-5 h-5 text-skin-base"
                      />
                    </DisclosureButton>

                    <DisclosurePanel class="pl-6 pt-4 pb-2">
                      <ul>
                        <li v-for="(sub2Item, s2) in subItem.children" :key="s2">
                          <button
                              v-if="isModule(sub2Item)"
                              @click="handleClick(sub2Item)"
                              :class="{'text-skin-accent1': isActiveRoute(sub2Item)}"
                              class=" text-left text-skin-heading rounded-md w-full px-2 py-2 focus:outline-none focus:ring-1 focus:ring-skin-accent1 hover:text-skin-accent1"
                          >
                            <span>{{ sub2Item.displayName }}</span>
                          </button>
                        </li>
                      </ul>
                    </DisclosurePanel>
                  </Disclosure>
                </li>
              </ul>
            </DisclosurePanel>
          </transition>
        </Disclosure>
      </li>
    </ul>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {ChevronUpIcon, HomeIcon} from "@heroicons/vue/solid"
import {Disclosure, DisclosureButton, DisclosurePanel} from "@headlessui/vue";

export default {
  name: "side-nav-bar",
  components: {
    HomeIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronUpIcon
  },
  data() {
    return {
      defaultMenu: [
        {
          displayName: 'Dashboard',
          icon: 'HomeIcon',
          routeName: 'Dashboard'
        }
      ],
      defaultIcon: "<svg class=\"w-6 h-6\" fill=\"none\" stroke=\"currentColor\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4\"></path></svg>"
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
    menu() {
      return this.$store.state.auth.user.menu || this.defaultMenu
    },
    icons() {
      return this.$store.state.auth.icons || []
    }
  },
  methods: {
    handleClick(menuItem) {
      if (menuItem.routeName) {
        if (this.$store.state.mobileMenuOpen) {
          this.$store.dispatch('toggleMobileMenu')
        }
        this.$router.push({name: menuItem.routeName})
      }
    },
    isActiveRoute(menuItem) {
      return this.currentRouteName === menuItem.routeName
    },
    isModule(menuItem) {
      return Object.prototype.hasOwnProperty.call(menuItem, "routeName")
    },
    getIcon(menuItem) {
      if (!this.icons.length) {
        return this.defaultIcon
      }
      const filteredIcons = this.icons.filter(icon => icon.groupName === menuItem.displayName || icon.groupName === menuItem.groupName)
      if(!filteredIcons.length) {
        return this.defaultIcon
      }
      return filteredIcons[0].svg
    }
  },
}
</script>

<style scoped>

</style>