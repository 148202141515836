import ApiService from "@/services/api.service";

export const salesRepDashboard = {
    namespaced: true,
    state() {
        return {
            details: {}
        }
    },
    actions: {
        loadData( {commit}, email) {
            return ApiService.post(`/rep-dashboard/${email}`)
                .then(response => {
                    //console.log(response.data)
                    commit('saveData', response.data)
                })
                .catch(error => Promise.reject(error))
        },
        sendData ( {commit}, sendDataObj) {
            //console.log('inputData = ' + sendDataObj.inputData + ', email = ' + sendDataObj.email)
            return ApiService.postJson(`/rep-dashboard/${sendDataObj.email}/update`, sendDataObj.inputData)
                .then(response => {
                    //console.log(response.data)
                    commit('saveData', response.data)
                })
                .catch(error => Promise.reject(error))
        }
    },
    mutations: {
        saveData(state, response) {
            state.details = JSON.parse(response.repDashboardInfo)
            //console.log('repDashboardInfo: ' + JSON.stringify(JSON.parse(response.repDashboardInfo)))
        }
    }
}