<template>
  <div class="bg-skin-fill caret-skin-accent1 selection:bg-skin-accent1 selection:text-skin-inverted text-skin-base min-h-screen sm:px-4 py-4 flex flex-col" :class="theme">
    <nav-bar/>
    <div class="flex-1 flex w-full">
      <side-nav-bar class="hidden lg:block lg:w-1/4 lg:max-w-sm" v-if="isAuthenticated"/>
      <main class="flex-1 w-full lg:w-3/4">
        <router-view/>
      </main>
    </div>
    <side-nav-bar-mobile class="lg:hidden" :class="theme"/>
    <AppNotifications/>
  </div>
</template>
<script>
import {PublicClientApplication} from "@azure/msal-browser"
import msal from "@/configs/msal.config"
import {mapState} from "vuex";
import NavBar from "@/components/NavBar";
import SideNavBar from "@/components/SideNavBar";
import SideNavBarMobile from "@/components/SideNavBarMobile";
import AppNotifications from "@/components/AppNotifications";
import showError from "@/services/error.service";

export default {
  name: 'App',
  data() {
    return {
      prefersDarkScheme: false
    }
  },
  computed: {
    theme() {
      if (this.user.systemTheme) {
        return this.prefersDarkScheme ? this.user.defaultDarkTheme : this.user.defaultLightTheme
      }
      return this.user.defaultTheme
    },
    ...mapState('auth', ["msalConfig", "isAuthenticated", "user", "account"])
  },
  components: {
    AppNotifications,
    NavBar,
    SideNavBar,
    SideNavBarMobile
  },
  methods: {
    getUserData() {

      this.$store.dispatch('auth/getUserData')
          .then(() => {
            this.$gtag.set({
              'user_properties': {
                'username': this.account.username,
                'theme': this.user.theme,
                'prefers_dark': this.user.prefersDark
              }
            })
            this.$store.dispatch('auth/getMenu')
                .then(() => {
                  if (this.$route.name === 'ServiceUnavailable') {
                    this.$router.push({name: 'Home'})
                  }
                })
                .catch(error => {
                  this.$notify({
                    group: 'important',
                    title: "Error Getting Menu",
                    type: 'error',
                    text: showError(error)
                  }, 10000)
                })
          })
          .catch(error => {
            this.$router.push({name: "ServiceUnavailable"})
            this.$notify({
              group: 'important',
              title: "Error Getting User Data",
              type: 'error',
              text: showError(error)
            }, 10000)
          })
    }
  },
  async created() {
    msal.$msalInstance = new PublicClientApplication(this.msalConfig)
    msal.$msalInstance.handleRedirectPromise()
        .then(async response => {
          if (response !== null) {
            await this.$store.dispatch('auth/setAccount', response.account)
          } else {
            const accounts = msal.$msalInstance.getAllAccounts()
            if (accounts.length > 0) {
              await this.$store.dispatch('auth/setAccount', accounts[0])
            }
          }
          if (this.isAuthenticated) {
            this.getUserData()
          }
        })
  },
  mounted() {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
    this.prefersDarkScheme = darkModeMediaQuery.matches
    this.$store.dispatch('auth/setTheme', this.theme)
    this.$store.dispatch('auth/setPrefersDark', this.prefersDarkScheme)
    this.$nextTick(() => {
      darkModeMediaQuery.addEventListener('change', e => {
        this.prefersDarkScheme = e.matches
        this.$store.dispatch('auth/setPrefersDark', this.prefersDarkScheme)
        this.$store.dispatch('auth/setTheme', this.theme)
        this.$gtag.set({
          'user_properties': {
            'theme': this.user.theme,
            'prefers_dark': this.user.prefersDark
          }
        })
      })
    })
    // const accounts = msal.$msalInstance.getAllAccounts()
    // if (accounts.length === 0) {
    //   return
    // }
    // this.$store.dispatch('auth/setAccount', accounts[0])
    //     .then(() => {
    if (this.isAuthenticated) {
      this.getUserData()
    }
    // })
    // .catch(error => {
    //   this.$notify({
    //     group: 'important',
    //     title: "Error Setting User Account",
    //     type: 'error',
    //     text: showError(error)
    //   }, 10000)
    // })
  }
}
</script>
