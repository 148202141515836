<template>
  <section
      class="mt-6 px-4 py-8 bg-skin-section shadow-lg sm:rounded-lg max-w-4xl ">
    <h3 class="text-skin-heading font-medium tracking-wide text-lg">Environment</h3>
    <dl>
      <div class="px-4 py-5 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-skin-heading sm:col-span-3">
          NODE_ENV
        </dt>
        <dd class="mt-1 text-sm text-skin-base sm:mt-0 sm:col-span-4">
          {{ nodeEnvironment }}
        </dd>
      </div>
      <div class="px-4 py-5 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-skin-heading sm:col-span-3">
          VUE_APP_ENV
        </dt>
        <dd class="mt-1 text-sm text-skin-base sm:mt-0 sm:col-span-4">
          {{ vueEnvironment }}
        </dd>
      </div>
      <div class="px-4 py-5 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-skin-heading sm:col-span-3">
          BASE_URL
        </dt>
        <dd class="mt-1 text-sm text-skin-base sm:mt-0 sm:col-span-4">
          {{ baseUrl }}
        </dd>
      </div>
      <div class="px-4 py-5 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-skin-heading sm:col-span-3">
          VUE_APP_BASE_URL
        </dt>
        <dd class="mt-1 text-sm text-skin-base sm:mt-0 sm:col-span-4">
          {{ baseApiUrl }}
        </dd>
      </div>
      <div class="px-4 py-5 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-skin-heading sm:col-span-3">
          VUE_APP_AZURE_REDIRECT
        </dt>
        <dd class="mt-1 text-sm text-skin-base sm:mt-0 sm:col-span-4">
          {{ redirect }}
        </dd>
      </div>
      <div class="px-4 py-5 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-skin-heading sm:col-span-3">
          VUE_APP_AZURE_POST_LOGOUT_REDIRECT
        </dt>
        <dd class="mt-1 text-sm text-skin-base sm:mt-0 sm:col-span-4">
          {{ postLogoutRedirect }}
        </dd>
      </div>
      <div class="px-4 py-5 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-skin-heading sm:col-span-3">
          Redirect from state
        </dt>
        <dd class="mt-1 text-sm text-skin-base sm:mt-0 sm:col-span-4">
          {{ redirectState }}
        </dd>
      </div>
      <div class="px-4 py-5 sm:grid sm:grid-cols-7 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-skin-heading sm:col-span-3">
          Msal redirect
        </dt>
        <dd class="mt-1 text-sm text-skin-base sm:mt-0 sm:col-span-4">
          {{ msalRedirect }}
        </dd>
      </div>
    </dl>
  </section>
</template>

<script>
import msal from "@/configs/msal.config";

export default {
  name: "Information",
  computed: {
    nodeEnvironment() {
      return process.env.NODE_ENV
    },
    vueEnvironment() {
      return process.env.VUE_APP_ENV
    },
    redirect() {
      return process.env.VUE_APP_AZURE_REDIRECT
    },
    postLogoutRedirect() {
      return process.env.VUE_APP_AZURE_POST_LOGOUT_REDIRECT
    },
    redirectState() {
      return this.$store.state.auth.msalConfig.auth.redirectUri
    },
    baseApiUrl() {
      return process.env.VUE_APP_BASE_URL
    },
    baseUrl() {
      return process.env.BASE_URL
    },
    msalRedirect() {
      return msal.$msalInstance.config.auth.redirectUri
    }
  }
}
</script>

<style scoped>

</style>