export default function showError(error) {
    if (!error) return "No error message"

    if (error.response) {
        if (error.response.data) {
            if (error.response.data.errors && Array.isArray(error.response.data.errors)) {
                return  error.response.data.errors.reduce((a, e) => {
                    return a + e.defaultMessage + ' - \r\n'
                }, '')
            }
            if (error.response.data.errorMessage) {
                return error.response.data.errorMessage
            }
            if (error.response.data.message && error.response.data.message !== "No message available") {
                return error.response.data.message
            }
            if (error.response.data.error && typeof error.response.data.error === "string") {
                if (error.response.data.error === 'Not Found'){
                    return error.response.data.error + " " + error.response.data.path
                }
                return error.response.data.error
            }
        }
    }

    if (error.message) {
        return error.message
    }

    return error.toString()

}