<template>
  <div class="px-4 sm:px-0">
    <h4 class="mt-16 font-medium uppercase tracking-wide text-xl text-skin-accent1">Error connecting</h4>
    <h1 class="mt-6 font-bold text-4xl sm:text-6xl text-skin-heading">Service Unavailable</h1>
    <p class="mt-6 text-lg">Sorry, it looks like we're having issues connecting to Momentum's system.</p>
    <p class="text-lg mt-2">Try reloading in a bit.</p>
    <p class="text-lg mt-2">If this keeps happening, consider opening a support ticket by emailing <span class="select-all">support@momtex.com</span></p>
  </div>
</template>

<script>
export default {
  name: "Service Unavailable",
}
</script>
