export const afcGlobal = {
    namespaced: true,
    state() {
        return {
            listWatcherRunning: false,
            reqWatcherRunning: false
        }
    },
    actions: {
        setAfcListWatcherFlag ( {commit}, value) {
            commit('saveListData', value)
        },
        setAfcRequestWatcherFlag ( {commit}, value) {
            commit('saveRequestData', value)
        }
    },
    mutations: {
        saveListData(state, value) {
            state.listWatcherRunning = value
        },
        saveRequestData(state, value) {
            state.reqWatcherRunning = value
        }
    }
}