import axios from "axios"
import store from "@/store"

class GraphService {
    $axios = axios.create({
        baseURL: process.env.VUE_APP_GRAPH_BASE_URL
    })

    async get(url, {params= {}, responseType= '', headers = {}} = {params: {}, responseType:'', headers: {}}) {
        await store.dispatch('auth/getGraphToken')
        const defaultHeader = {'Authorization': 'Bearer ' + store.state.auth.graphTokenResponse.accessToken}
        return this.$axios
            .get(url, {
                headers: { ...defaultHeader, ...headers},
                responseType,
                params
            })
            .then(response => response)
            .catch(error => Promise.reject(error))
    }

    async post(url, {data= {}} = {data: {}}) {
        await store.dispatch('auth/getGraphToken')
        return this.$axios
            .post(url, data, {
                headers: {
                    'Authorization': 'Bearer ' + store.state.auth.graphTokenResponse.accessToken
                }
            })
            .then(response => response)
            .catch(error => Promise.reject(error))
    }
}

export default new GraphService()