import axios from "axios"
import store from "@/store"

class ApiService {
    $axios = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL
    })

    async get(url, {params} = {}) {
        await store.dispatch('auth/getToken')
        return this.$axios
            .get(url, {
                headers: this.getHeaders(),
                params
            })
            .then(response => response)
            .catch(error => Promise.reject(error))
    }

    getHeaders() {
        const headers = {
            'Authorization': 'Bearer ' + store.state.auth.tokenResponse.accessToken,
        };
        if (store.state.auth.impersonating?.user) {
            headers['Impersonate-User'] = store.state.auth.impersonating?.user.toLowerCase() || null
            headers['Impersonate-Groups'] = store.state.auth.impersonating?.groups || null
        }

        return headers;
    }

    async post(url, body = {}) {
        await store.dispatch('auth/getToken')
            .catch(error => Promise.reject(error))
        return this.$axios
            .post(url, body, {
                headers: this.getHeaders()
            })
            .then(response => response)
            .catch(error => Promise.reject(error))
    }

    async postJson(url, body) {
        const headers = this.getHeaders()
        headers['Content-Type'] = 'application/json'
        await store.dispatch('auth/getToken')
            .catch(error => Promise.reject(error))
        return this.$axios
            .post(url, body, {
                headers: headers
            })
            .then(response => response)
            .catch(error => Promise.reject(error))
    }

    async delete(url) {
        await store.dispatch('auth/getToken')
            .catch(error => Promise.reject(error))
        return this.$axios
            .delete(url, {
                headers: this.getHeaders()
            })
            .then(response => response)
            .catch(error => Promise.reject(error))
    }

}

export default new ApiService()