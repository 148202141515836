<template>
  <nav class="flex justify-between items-center w-full mb-6 px-4 sm:px-0">
    <div class="w-12 lg:hidden" v-if="isAuthenticated">
      <button @click="toggleMobileMenu">
        <MenuIcon class="w-6 h-6"></MenuIcon>
      </button>
    </div>
    <div class="flex-1 lg:flex-none lg:w-1/4 lg:max-w-sm flex justify-center items-center">
        <app-logo/>
    </div>
    <div class="lg:flex-1 flex justify-between items-center">
      <div> <!--Search bar --> </div>
      <profile-menu v-if="isAuthenticated"/>
      <div v-else>
        <button @click="signIn">Sign In</button>
      </div>
    </div>
  </nav>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AppLogo from "@/components/AppLogo";
import ProfileMenu from "@/components/ProfileMenu";
import {MenuIcon} from "@heroicons/vue/solid"
import showError from "@/services/error.service";

export default {
  name: "NavBar",
  components: {
    ProfileMenu,
    AppLogo,
    MenuIcon
  },
  computed: {
    ...mapState('auth', ["isAuthenticated"])
  },
  methods: {
    signIn() {
      this.$store.dispatch('auth/signIn')
          .then(() => {
            this.$store.dispatch('auth/getUserData')
                .then(() => {
                  this.$router.push({name: 'Dashboard'})
                })
                .catch(error => {
                  this.$notify({
                    group: 'important',
                    title: "Error Getting User Data",
                    type: 'error',
                    text: showError(error)
                  }, 10000)
                  this.$router.push({name: "ServiceUnavailable"})
                })
          })
          .catch(error => {
            this.$notify({
              group: 'important',
              title: "Error Signing In",
              type: 'error',
              text: showError(error)
            }, 10000)
            this.$router.push({name: 'Home'})
          })
    },
    ...mapActions(["toggleMobileMenu"])
  }
}
</script>

