import Home from "@/views/Home";
import Info from "@/views/Info";
import ServiceUnavailable from "@/views/errors/503";
import NotFound from "@/views/NotFound";

export default [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: ''
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { title: 'Dashboard', requiresAuth: true },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
    },
    {
        path: '/my-account',
        name: 'MyAccount',
        meta: { title: 'My Account', requiresAuth: true },
        component: () => import(/* webpackChunkName: "myAccount" */ '@/views/MyAccount.vue')
    },
    {
        path: '/info',
        name: 'Information',
        meta: { title: 'Information', requiresAuth: false },
        component: Info
    },
    {
        path: '/sw',
        meta: { title: 'Sherwin Williams EDI', requiresAuth: true },
        component: () => import(/* webpackChunkName: "sw" */ '@/views/SW.vue'),
        children: [
            {
                path: "",
                name: "SherwinWilliams",
                component: () => import(/* webpackChunkName: "sw" */ '@/components/sw/SwDashboard.vue')
            },
            {
                path: "orders",
                name: "SherwinWilliamsOrders",
                component: () => import(/* webpackChunkName: "sw" */ '@/components/sw/SwOrders.vue')
            },
            {
                path: "invoices",
                name: "SherwinWilliamsInvoices",
                component: () => import(/* webpackChunkName: "sw" */ '@/components/sw/SwInvoices.vue')
            },
            {
                path: "acks",
                name: "SherwinWilliamsAcks",
                component: () => import(/* webpackChunkName: "sw" */ '@/components/sw/SwAcks.vue')
            },
            {
                path: "errors",
                name: "SherwinWilliamsErrors",
                component: () => import(/* webpackChunkName: "sw" */ '@/components/sw/SwErrors.vue')
            },
        ]
    },
    {
        path: '/sw/:custPo/:assignedId',
        name: 'SherwinWilliamsOrderDetails',
        meta: { title: 'SW Order Details', requiresAuth: true },
        component: () => import(/* webpackChunkName: "sw" */ '@/views/SwOrderDetails.vue')
    },
    {
        path: '/admin/users',
        name: 'AdminUsers',
        meta: { title: 'Users Admin', requiresAuth: true },
        component: () => import(/* webpackChunkName: "customs" */ '@/views/admin/Users.vue')
    },
    {
        path: '/admin/users/:id',
        name: 'AdminUserDetails',
        meta: { title: 'User Details', requiresAuth: true },
        component: () => import(/* webpackChunkName: "customs" */ '@/views/admin/UserDetails.vue')
    },
    {
        path: '/admin/modules',
        name: 'AdminModules',
        meta: { title: 'Modules Admin', requiresAuth: true },
        component: () => import(/* webpackChunkName: "customs" */ '@/views/admin/Modules.vue')
    },
    {
        path: '/customs',
        name: 'CustomsProcess',
        meta: { title: 'Customs Process', requiresAuth: true },
        component: () => import(/* webpackChunkName: "customs" */ '@/views/customsProcess/Customs.vue')
    },
    {
        path: '/customs/request/create',
        name: 'CustomsProcessCreateRequest',
        meta: { title: 'Create Request', requiresAuth: true },
        component: () => import(/* webpackChunkName: "customs" */ '@/views/customsProcess/CustomsCreate.vue')
    },
    {
        path: '/customs/request/:reqId/edit',
        name: 'CustomsProcessEditRequest',
        meta: { title: 'Edit Request', requiresAuth: true },
        component: () => import(/* webpackChunkName: "customs" */ '@/views/customsProcess/CustomsCreate.vue')
    },
    {
        path: '/customs/request/:reqId/review',
        name: 'CustomsProcessReviewRequest',
        meta: { title: 'Review Request', requiresAuth: true },
        component: () => import(/* webpackChunkName: "customs" */ '@/views/customsProcess/CustomsReview.vue')
    },
    {
        path: '/dsm',
        name: 'DirectShipMaintenance',
        meta: { title: 'Direct Ship Maintenance', requiresAuth: true },
        component: () => import(/* webpackChunkName: "dsm" */ '@/views/dsm/Dsm.vue')
    },
    {
        path: '/ups-api-logs',
        name: 'UPSApiLogs',
        meta: { title: 'UPS API Logs', requiresAuth: true },
        component: () => import(/* webpackChunkName: "dsm" */ '@/views/upsApiLogs/UpsApiLogs.vue')
    },
    {
        path: '/collection-account-assignment',
        name: 'CollectionAccountAssignment',
        meta: { title: 'Collection Account Assignment', requiresAuth: true },
        component: () => import(/* webpackChunkName: "colAssign" */ '@/views/colAssign/ColAssign.vue')
    },
    {
        path: '/specifier-updates',
        name: 'SpecifierUpdates',
        meta: { title: 'Specifier Updates', requiresAuth: true },
        component: () => import(/* webpackChunkName: "specifierUpdates" */ '@/views/specifierUpdates/SpecifierUpdates')
    },
    {
        path: '/todays-revenue',
        name: 'TodaysRevenue',
        meta: { title: 'Today\'s Revenue', requiresAuth: true },
        component: () => import(/* webpackChunkName: "todaysRevenue" */ '@/views/todaysRevenue/TodaysRevenue')
    },
    {
        path: '/dictionary',
        meta: { title: 'Universe Dictionary', requiresAuth: true },
        component: () => import(/* webpackChunkName: "dictionaries" */ '@/views/dictionaries/Dictionaries.vue'),
        children: [
            {
                path: "",
                name: 'Dictionaries',
                component: () => import(/* webpackChunkName: "dictionaries" */ '@/views/dictionaries/Empty.vue'),
            },
            {
                path: ':file',
                name: "Dictionary",
                component: () => import(/* webpackChunkName: "dictionaries" */ '@/views/dictionaries/Dictionary.vue')
            }
        ]
    },
    {
        path: '/code-search',
        name: 'CodeSearch',
        meta: { title: 'Code Search', requiresAuth: true },
        component: () => import(/* webpackChunkName: "codeSearch" */ '@/views/codeSearch/CodeSearch.vue')
    },
    {
        path: '/code-file',
        meta: { title: 'Code File', requiresAuth: true },
        component: () => import(/* webpackChunkName: "codeFile" */ '@/views/codeFile/CodeFile.vue'),
        children: [
            {
                path: "",
                name: 'CodeFiles',
                component: () => import(/* webpackChunkName: "codeFile" */ '@/views/codeFile/Empty.vue'),
            },
            {
                path: ':code',
                name: "CodeFile",
                component: () => import(/* webpackChunkName: "codeFile" */ '@/views/codeFile/File.vue')
            }
        ]
    },
    {
        path: '/library-registration',
        meta: { title: 'Library Registration', requiresAuth: true },
        component: () => import(/* webpackChunkName: "library" */ '@/views/library/Library.vue'),
        children: [
            {
                path: "",
                name: 'Library',
                component: () => import(/* webpackChunkName: "library" */ '@/views/library/LibraryCustomers.vue'),
            },
            {
                path: ':account',
                name: "Registration",
                component: () => import(/* webpackChunkName: "library" */ '@/views/library/LibraryRegistration.vue')
            }
        ]
    },
    {
        path: '/intacct',
        meta: { title: 'Intacct', requiresAuth: true },
        component: () => import(/* webpackChunkName: "intacct" */ '@/views/intacct/Intacct.vue'),
        children: [
            {
                path: "",
                name: 'Intacct Logs',
                component: () => import(/* webpackChunkName: "intacct" */ '@/views/intacct/IntacctLogs.vue'),
            },
            {
                path: "invoices",
                name: 'Intacct Invoices',
                component: () => import(/* webpackChunkName: "intacct" */ '@/views/intacct/IntacctInvoices.vue'),
            },
            {
                path: "payments",
                name: 'Intacct Payments',
                component: () => import(/* webpackChunkName: "intacct" */ '@/views/intacct/IntacctPayments.vue'),
            }
        ]
    },
    {
        path: '/afc/request/create',
        name: 'AfcEntry-Create',
        meta: { title: 'New AFC Request', requiresAuth: true },
        component: () => import(/* webpackChunkName: "afc" */ '@/views/afc/AfcEntry.vue')
    },
    {
        // For reps
        path: '/afc/request/:reqId/edit/:pageMode',
        name: 'AfcEntry-Edit',
        meta: { title: 'Edit AFC Request', requiresAuth: true },
        component: () => import(/* webpackChunkName: "afc" */ '@/views/afc/AfcEntry.vue')
    },
    {
        // For PPS
        path: '/afc/request/:reqId/update/:pageMode',
        name: 'AfcUpdate',
        meta: { title: 'PPS AFC Maintenance', requiresAuth: true },
        component: () => import(/* webpackChunkName: "afc" */ '@/views/afc/AfcUpdate.vue')
    },
    {
        // For PPS and reps
        path: '/afc/rep-listing',
        name: 'AfcListing-Rep',
        meta: { title: 'Rep AFC Listing', requiresAuth: true },
        component: () => import(/* webpackChunkName: "afc" */ '@/views/afc/AfcListing.vue')
    },
    {
        // For PPS
        path: '/afc/inquiry-update-listing',
        name: 'AfcListing-InqUpd',
        meta: { title: 'AFC Inquiry/Update Listing', requiresAuth: true },
        component: () => import(/* webpackChunkName: "afc" */ '@/views/afc/AfcListing.vue')
    },
    {
        // For reps
        path: '/afc/open-inquiry-listing',
        name: 'AfcListing-OpenInq',
        meta: { title: 'Open AFC Inquiry Listing', requiresAuth: true },
        component: () => import(/* webpackChunkName: "afc" */ '@/views/afc/AfcListing.vue')
    },
    {
        // For PPS
        path: '/afc/pps-maint-listing',
        name: 'AfcPpsListing-Maint',
        meta: { title: 'PPS AFC Maintenance Listing', requiresAuth: true },
        component: () => import(/* webpackChunkName: "afc" */ '@/views/afc/AfcPpsListing.vue')
    },
    {
        // For reps
        path: '/afc/compl-hist-listing-rep',
        name: 'AfcPpsListing-Hist-Rep',
        meta: { title: 'Completed AFC History', requiresAuth: true },
        component: () => import(/* webpackChunkName: "afc" */ '@/views/afc/AfcPpsListing.vue')
    },
    {
        // For PPS
        path: '/afc/compl-hist-listing-pps',
        name: 'AfcPpsListing-Hist-Pps',
        meta: { title: 'Completed AFC History', requiresAuth: true },
        component: () => import(/* webpackChunkName: "afc" */ '@/views/afc/AfcPpsListing.vue')
    },
    {
        // For PPS
        path: '/afc/compl-rep-pages-listing',
        name: 'AfcPpsListing-Rep-Pages',
        meta: { title: 'Completed AFC Rep Page', requiresAuth: true },
        component: () => import(/* webpackChunkName: "afc" */ '@/views/afc/AfcPpsListing.vue')
    },
    {
        // For PPS
        path: '/afc/killed-listing',
        name: 'AfcPpsListing-Killed',
        meta: { title: 'Killed AFC Listing', requiresAuth: true },
        component: () => import(/* webpackChunkName: "afc" */ '@/views/afc/AfcPpsListing.vue')
    },
    {
        path: '/error/service-unavailable',
        name: 'ServiceUnavailable',
        meta: { title: 'Service Unavailable', requiresAuth: false },
        component: ServiceUnavailable
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound
    }
]