<template>
  <Menu as="div" class="relative inline-block" v-if="userLoadingState !== 'loading'">
    <MenuButton v-if="!hasProfilePic"
                id="avatarInitials"
                class="relative w-12 h-12 rounded-full shadow-lg bg-skin-accent1 text-skin-inverted flex items-center justify-center font-medium tracking-widest">
      {{ initials }}
      <ImpersonateIcon class="absolute -top-1 -right-1 text-skin-accent3 w-4 h-4" v-if="isImpersonating" />
    </MenuButton>
    <MenuButton v-if="hasProfilePic"
                class="relative rounded-full shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
      <img id="avatarImage" :src="profilePhoto" class="w-12 h-12 rounded-full"
           alt="avatar">
      <ImpersonateIcon class="absolute -top-1 -right-1 text-skin-accent1 w-4 h-4" v-if="isImpersonating" />
    </MenuButton>

    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
          class="z-50 absolute right-0 w-56 mt-2 origin-top-right backdrop-blur-xl bg-skin-card/50 bg-gradient-to-t from-skin-accent1/10 divide-y divide-skin-border rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border border-skin-border border-opacity-30">

        <div class="px-1 py-1" v-if="isImpersonating">
          <MenuItem v-slot="{ active }">
            <button
                :class="[
                        active ? 'bg-skin-accent1 text-skin-inverted' : 'text-skin-base',
                        'group flex rounded-md items-center w-full px-5 py-2 text-sm'
                    ]"
                @click="stopImpersonating">Stop Impersonating
            </button>
          </MenuItem>
        </div>

        <div class="px-1 py-1">
          <MenuItem v-slot="{ active }">
            <router-link
                :to="{name: 'MyAccount'}"
                :class="[
                        active ? 'bg-skin-accent1 text-skin-inverted' : 'text-skin-base',
                        'group flex rounded-md items-center w-full px-5 py-2 text-sm'
                    ]"
            >My Account
            </router-link>
          </MenuItem>
        </div>
        <div class="px-1 py-1">
          <MenuItem v-slot="{ active }">
            <button
                :class="[
                        active ? 'bg-skin-accent1 text-skin-inverted' : 'text-skin-base',
                        'group flex rounded-md items-center w-full px-5 py-2 text-sm'
                    ]"
                @click="signOut">Sign Out
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>

  <div v-else>Logging in...</div>
</template>
<script>
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import showError from "@/services/error.service";
import ImpersonateIcon from "@/components/ImpersonateIcon.vue";

export default {
  name: 'profile-menu',
  components: {
    ImpersonateIcon,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  computed: {
    isImpersonating(){
      return !!this.$store.state.auth.impersonating
    },
    initials() {
      let name = this.$store.state.auth.account.name
      if (this.$store.state.auth.impersonating?.name) {
        name = this.$store.state.auth.impersonating.name
      }

      return name.split(" ").map(n => n[0]).join("")
    },
    hasProfilePic() {
      return !!this.$store.state.auth.user.photo
    },
    profilePhoto() {
      return `data:image/jpeg;base64,${btoa(this.$store.state.auth.user.photo)}`
    },
    userLoadingState() {
      return this.$store.state.auth.userLoadingState
    }
  },

  methods: {
    stopImpersonating() {
      this.$store.dispatch('auth/stopImpersonating')
          .then(()=> {
            this.$store.dispatch('auth/getUserData')
                .then(() => {
                  this.$store.dispatch('auth/getMenu')
                      .then(() => {
                        this.$router.push({name: 'Dashboard'})
                      })
                })
          })
    },
    signOut() {
      this.$store.dispatch('auth/signOut')
          .then(() => {
            this.$notify({
              group: 'default',
              title: "Signed Out",
              type: 'success',
              text: 'Sign out successful'
            }, 5000)
          })
          .catch(error => {
            this.$notify({
              group: 'important',
              title: "Error Signing Out",
              type: 'error',
              text: showError(error)
            }, 10000)
          })
          .finally(() => {
            this.$router.push({name: 'Home'})
          })
    }
  }
}
</script>