<template>
  <div>
    <h1 class="text-xl text-skin-heading px-4 sm:px-0">Not Found</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>