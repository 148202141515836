import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store"
import {nextTick} from "vue";
import routes from './routes'
import msal from "@/configs/msal.config"

const DEFAULT_TITLE = process.env.VUE_APP_TITLE

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const routeRequiresAuth = to.matched.some(record => record.meta.requiresAuth)
    if (!routeRequiresAuth) {
        next();
        return
    }

    function proceed() {
        const userIsLoaded = store.state.auth.userLoadingState === 'loaded'
        // const authIsNotYetSet = store.state.auth.userLoadingState === 'initial'
        const accountsDoNotExist = !store.state.auth.isAuthenticated
        const loginIsNotInProgress = !msal.$msalInstance.interactionInProgress()

        if (userIsLoaded) {
            next();
            return
        }

        if (accountsDoNotExist && loginIsNotInProgress) {
            store.dispatch('auth/signIn')
                .then(() => {
                    store.dispatch('auth/getUserData')
                        .then(() => {
                            next()
                        })
                        .catch(() => {
                            next({name: 'Home'})
                        })
                })
        } else {
            next({name: 'Home'})
        }
    }

    function needsToWait() {
        const userDataIsLoading = store.state.auth.userLoadingState === 'loading'
        const appIsInInitialState = store.state.auth.userLoadingState === 'initial'
        const loginIsInProgress = msal.$msalInstance.interactionInProgress()
        const accountsExist = store.state.auth.isAuthenticated

        return userDataIsLoading ||
            (appIsInInitialState && (loginIsInProgress || accountsExist))
    }

    // If the app is still loading up and trying to authenticate or get the user data from the ERP then wait for it to finish
    if (needsToWait()) {
        store.watch(
            (state) => state.auth.userLoadingState,
            (value) => {
                if (value === 'loaded') {
                    proceed()
                }
            }
        )
    } else {
        proceed()
    }
})

router.afterEach((to) => {
    nextTick(() => {
        document.title = to.meta.title ? DEFAULT_TITLE + ' - ' + to.meta.title : DEFAULT_TITLE
    })
})

export default router
