import {createStore} from 'vuex'
import {auth} from "@/store/auth.module";
import {salesRepDashboard} from '@/store/salesRepDashboard.module'
import {afcRequest} from '@/store/afcRequest.module'
import {afcGlobal} from '@/store/afcGlobal.module'

export default createStore({
    state: {
        mobileMenuOpen: false
    },
    mutations: {
        toggleMobileMenu(state) {
            state.mobileMenuOpen = !state.mobileMenuOpen
        }
    },
    actions: {
        toggleMobileMenu({commit}) {
            commit('toggleMobileMenu')
        }
    },
    modules: {
        auth,
        salesRepDashboard,
        afcRequest,
        afcGlobal
    },
    // plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : []
})